import {BrowserRouter, Route, Routes} from "react-router-dom";
import Status from "./components/Status";
import TopupOptions from "./components/TopupOptions";
import PaymentReturn from "./components/PaymentReturn";
import PaymentError from "./components/PaymentError";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Status/>}/>
        <Route path="/esim/:id" element={<Status/>}/>
        <Route path="/esim/:id/topups" element={<TopupOptions/>}/>
        <Route path="/payment/return" element={<PaymentReturn/>}/>
        <Route path="/payment/error" element={<PaymentError/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

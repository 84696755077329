import {useEffect, useState} from "react";
import PackageCard from "../PackageCard";
import {useParams} from "react-router-dom";
import Spinner from "../Spinner";
import {apiHost} from "../../const";
import BackToShop from "../BackToShop";
import Footer from "../Footer";
import Header from "../Header";

function Status() {
  let { id } = useParams();
  const [esim, setEsim] = useState(id);
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(id !== undefined && packages.length === 0){
      checkStatus()
    }
  }, []);

  const checkStatus = () => {
    setPackages([]);
    setError(false)
    if (esim !== undefined && esim.length > 0) {
      setLoading(true)
      fetch(apiHost + `/esim/package/list?esim=${esim.trim()}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((response) => {
          if (response.status > 299) {
            setError(true)
          } else {
            return response.json()
          }
        })
        .then(data =>{
          setPackages(data.list);
          setError(false)
          setLoading(false)
        })
        .catch((err) => {
          setError(true)
          console.log(err.message)
          setLoading(false)
        });
    }
  }

  const listItems = () => {
    return packages
      .sort(function(a,b){return Date.parse(b.usageStart) -Date.parse(a.usageStart) })
      .map(pack =>
      <PackageCard packageData={pack} className="bg-white"/>);
  }

  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24">
      <Header/>

      <div className="flex-col text-center mb-6">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Sprawdź status eSIM</h1>
        <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">
          Zobacz ile danych pozostało na Twojej karcie eSIM. Podaj `Numer karty eSIM` (znajdziesz go w emailu z
          przesłanym kodem QR karty eSIM).
        </p>
        {error && (<p className="mt-6 text-lg leading-8 max-w-3xl text-red-700">
          Wystąpił problem z pobraniem statusu karty eSIM. Sprawdź czy numer karty jest poprawny lub <a href='https://esim5.com/formularz-kontaktowy/' className="text-green-700">skontaktuj się z pomocą</a>
        </p>)}
        <div className="mt-6 text-lg leading-8 text-gray-600 ">
          <input
            className="flex-initial text-center w-64 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username" type="text" placeholder="Numer karty eSIM" value={esim}
            onChange={event => setEsim(event.target.value)}></input>
        </div>
        <div className="mt-6 items-center justify-between content-center	">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={checkStatus}>
            Sprawdź eSIM
          </button>
        </div>
      </div>

      {loading && (<Spinner isVisible={loading} />)}

      {error === false && packages !== undefined && packages.length > 0 && (
        <div>
          <div className="text-center">
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <p
                className="bg-green-300 inline-block  rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2 leading-6 text-gray-900">Potrzebujesz
                więcej danych?</p>
            </div>
            <a href={'/esim/' + id + '/topups'}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Kup doładowanie
              </button>
            </a>
          </div>

          <div className="text-center">
            <h2
              className="m-9 text-center text-2xl font-bold leading-7 text-gray-700 sm:truncate sm:text-3xl sm:tracking-tight">
              eSIM <p className="text-black">{esim}</p></h2>
            <div className="grid  gap-4 :grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-6">
              {packages.length > 0 && listItems()}
            </div>
          </div>
        </div>
      )}

      <BackToShop/>
      <Footer/>
    </main>
  );
}

export default Status;

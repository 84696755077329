import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Spinner from "../Spinner";
import TopupCard from "../TopupCard";
import {apiHost} from "../../const";
import BackToShop from "../BackToShop";
import Footer from "../Footer";
import Header from "../Header";

function TopupOptions() {
  let {id} = useParams();
  const [selectedTopups, setSelectedTopups] = useState([]);
  const [selectedTopupsComp, setSelectedTopupsComp] = useState();
  const [esimFilter, setEsimFilter] = useState("");
  const [esim, setEsim] = useState(id);
  const [topupOptions, setTopupOptions] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    selectedTopupsLists()
  }, [selectedTopups, selectedTopupsComp]);

  useEffect(() => {
    if (id !== undefined && topupOptions.length === 0) {
      checkTopupOptions()
    }
  }, [selectedTopups]);

  const createOrder = (esimName, topups) => {
    setError(false)
    setLoading(true)
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        "esimName": esimName,
        "packages": topups
      })
    };

    fetch(apiHost + '/order/topup', requestOptions)
      .then((response) => {
        if (response.status > 299) {
          setError(true)
        } else {
          return response.json()
        }
      })
      .then(data => {
        window.location.href = data.approveUrl
        setError(false)
        setLoading(false)
      })
      .catch((err) => {
        setError(true)
        console.log(err)
        setLoading(false)
      });
  }

  const checkTopupOptions = () => {
    setTopupOptions([]);
    setError(false)
    if (esim !== undefined && esim.length > 0) {
      setLoading(true)
      fetch(apiHost+`/esim/topup/options?esim=${esim.trim()}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((response) => {
          if (response.status > 299) {
            setError(true)
          } else {
            return response.json()
          }
        })
        .then(data => {
          setTopupOptions(data.list);
          setError(false)
          setLoading(false)
        })
        .catch((err) => {
          setError(true)
          console.log(err.message)
          setLoading(false)
        });
    }
  }

  const listItems = () => {
    return topupOptions
      .filter(topupOptionData => topupOptionData.name.toLowerCase().includes(esimFilter.toLowerCase().trim(), 0))
      .map(topupOptionData =>
        <TopupCard topupData={topupOptionData} className="bg-green-300" onClickCallback={(isSelected, data) => {
          if (isSelected) {
            var temp = selectedTopups
            temp.push(data)
            setSelectedTopups(temp)
          } else {
            setSelectedTopups(selectedTopups.filter((elem) => {
              return (elem.sku + elem.variant) !== (data.sku + data.variant);
            }))
          }
        }}/>);
  }

  const selectedTopupsLists = () => {
    setSelectedTopupsComp(selectedTopups
      .map(topupOptionData =>
        <TopupCard topupData={topupOptionData} className="bg-green-300" onClickCallback={(isSelected, data) => {
        }}/>));
  }

  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24">
      <Header/>

      <div className="flex-col text-center mb-6">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Dokup doładowanie dla {esim}</h1>
        {error && (<p className="mt-6 text-lg leading-8 max-w-3xl text-red-700">
          Wystąpił problem z pobraniem opcji doładowań dla karty eSIM. <a href='https://esim5.com/formularz-kontaktowy/'
                                                                          className="text-green-700">Skontaktuj się z
          pomocą</a>
        </p>)}
        {selectedTopups.length > 0 && (
          <div className="mt-6 text-lg leading-8 text-gray-600 ">
            <h4
              className="m-9 text-center text-2xl font-bold leading-7 text-gray-700 sm:truncate sm:text-3xl sm:tracking-tight"
            >
              Wybrane pakiety
            </h4>
          <div className="grid  gap-4 :grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-6">
            {selectedTopupsComp}
          </div>
            <div className="mt-6 items-center justify-between content-center	">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
                createOrder(id, selectedTopups);
              }}>
                Idź do płatności
              </button>
            </div>
          </div>
        )}
      </div>

      {loading && (<Spinner isVisible={loading}/>)}

      {error === false && topupOptions !== undefined && topupOptions.length > 0 && (
        <div>
          <h4
            className="m-9 text-center text-2xl font-bold leading-7 text-gray-700 sm:truncate sm:text-3xl sm:tracking-tight"
          >
            Wybierz pakiety danych
          </h4>
          <div className="mt-6 text-lg leading-8 text-gray-600 mb-8 text-center">
            <input
              className="flex-initial text-center w-64 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="esimFilter" type="text" placeholder="Wyszukaj po nazwie" value={esimFilter}
              onChange={event => setEsimFilter(event.target.value)}></input>
          </div>
          <div className="grid  gap-4 :grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-6">
            {topupOptions.length > 0 && listItems()}
          </div>
        </div>
      )}

      <BackToShop/>
      <Footer/>
    </main>
  );
}

export default TopupOptions;

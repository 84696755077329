function BackToShop () {
  return (
    <div className="text-center mb-8">
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a href="https://esim5.com" className="text-sm font-semibold leading-6 text-gray-900">
          Wróć na stronę sklepu eSIM5.com ? <span aria-hidden="true">→</span>
        </a>
      </div>
    </div>
  )
}

export default BackToShop;
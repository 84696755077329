import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import BackToShop from "../BackToShop";
import Footer from "../Footer";
import Header from "../Header";

function PaymentError() {
  const search = useLocation().search;
  const paymentId = new URLSearchParams(search).get("paymentId");
  const data = new URLSearchParams(search).get("data");
  const [providerId, setProviderId] = useState(undefined);
  const [result, setResult] = useState(undefined);

  useEffect(() => {
    let payload = atob(data.split(".")[1])
    const obj = JSON.parse(payload);
    setProviderId(obj.paymentId)
    setResult(obj.result)
    //console.log(obj.externalPaymentId +" == "+ paymentId)
  }, [data]);

  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24">
      <Header/>

      <div className="flex-col text-center mb-6">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Niestety płatność nie powiodła się </h1>
        <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">
          Płatność numer: {providerId} Status: {result}
        </p>
        <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">
          Niestety zakup doładowania esim się nie powiódł. Środki na Twoim koncie mogły zostac zablokowane ale wciągu
          kilku dni zostaną zwrócone.
        </p>
        <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">Prosimy o ponowienie próby płatności
          lub nowe złożenie zamówienia w celu dokupienia pakietów danych do
        karty esim.
      </p>
      </div>

      <BackToShop/>
      <Footer/>
    </main>
  );
}

export default PaymentError;

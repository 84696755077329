
// class PackageType{}
// PackageType.prototype = {
//   dataLeft: String,
//   dataUsed: String,
//   isExpired: Boolean,
//   packageName: String,
//   packageVariant: String,
//   usageStart: Date,
//   usageEnd: Date
// };
/*
 var name : String? = null
    var sku : String? = null
    var variant : String? = null
      var capacity : Double? = null
      var validity : Int = 30 //days
    var network: String? = null
    var price: Int? = null //w groszach
    var currency: String? = "zł" //w groszach
    var locations: List<String>? = null
 */
import {useState} from "react";

function TopupCard({topupData, className, onClickCallback}) {
  const [selected, setSelected] = useState(false);
    return (<div  onClick={() => {
      setSelected(!selected)
      onClickCallback(!selected, topupData)
    }} className={`${selected?"bg-green-300":"bg-white"} max-w-sm rounded overflow-hidden shadow-lg `}>
      {/*<img className="w-full" src="/img/card-top.jpg" alt="Sunset in the mountains"/>*/}
      <div className="px-6 py-4" key={topupData.name + topupData.variant}>
        <div className="text-gray-700 font-bold text-xl mb-2">{topupData.name}</div>
        {topupData.capacity && (<p className="text-gray-700 text-base">
          Ilość danych: {topupData.capacity} GB
        </p>)}
        {topupData.validity && (<p className="text-gray-700 text-base">
          Ważność pakietu: {topupData.validity} dni
        </p>)}
        {topupData.price && (<p className="text-gray-700 text-base">
          Cena (brutto z VAT): {topupData.price/100} zł
        </p>)}
        {topupData.network && (<p className="text-gray-700 text-base">
          Obsługuje sieci: {topupData.network}
        </p>)}
        {/*<p className="text-gray-700 text-base">*/}
        {/*  <div>Dane użyte: {topupData.dataUsed} GB</div>*/}
        {/*  <div>Zostało danych: {topupData.dataLeft} GB</div>*/}
        {/*</p>*/}
        {/*<p className="text-gray-700 text-base">*/}
        {/*  Ważność eSIM od: */}
        {/*</p>*/}
      </div>
      <div className="px-6 pt-4 pb-2">
        {/*<span*/}
        {/*  className={`${topupData.isExpired ? "bg-red-200" : "bg-green-200"} inline-block  rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2`}>*/}
        {/*  {topupData.isExpired ? "Upłynął termin ważności" : "Pakiet może być używany"}*/}
        {/*</span>*/}
        {/*<span*/}
        {/*  className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>*/}
        {/*<span*/}
        {/*  className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>*/}
      </div>
    </div>)

}

export default TopupCard;
import {BallTriangle} from "react-loader-spinner";

function Spinner() {
  return (<BallTriangle
    height={100}
    width={100}
    radius={5}
    color="rgb(65,105,225)"
    ariaLabel="ball-triangle-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
  />)
}

export default Spinner;

// class PackageType{}
// PackageType.prototype = {
//   dataLeft: String,
//   dataUsed: String,
//   isExpired: Boolean,
//   packageName: String,
//   packageVariant: String,
//   usageStart: Date,
//   usageEnd: Date
// };

function PackageCard({packageData, className}) {
  const usageStart = packageData.usageStart ? (new Date(packageData.usageStart).toLocaleDateString('pl-PL')
    + " " + new Date(packageData.usageStart).toLocaleTimeString('pl-PL')) : "-"
  const usageEnd = packageData.usageEnd ? (new Date(packageData.usageEnd).toLocaleDateString()
    + " " + new Date(packageData.usageEnd).toLocaleTimeString('pl-PL')) : "-"
  const purchaseDate = packageData.purchaseDate ? (new Date(packageData.purchaseDate).toLocaleDateString()
    + " " + new Date(packageData.purchaseDate).toLocaleTimeString('pl-PL')) : "-"

  if(packageData.statusCheckSupported){
    return (<div className={`${className} max-w-sm rounded overflow-hidden shadow-lg`}>
      <div className="px-6 py-4" key={packageData.usageStart}>
        <div className="text-gray-700 font-bold text-xl mb-2">{packageData.packageName}</div>
        {packageData.packageVariant && (<p className="text-gray-700 text-base">
          Wariant pakietu: {packageData.packageVariant}
        </p>)}
        <p className="text-gray-700 text-base">
          <div>Dane użyte: {packageData.dataUsed} GB</div>
          <div>Zostało danych: {packageData.dataLeft} GB</div>
        </p>
        <p className="text-gray-700 text-base">
          Ważność eSIM od: {usageStart} do {usageEnd}
        </p>
      </div>
      <div className="px-6 pt-4 pb-2">
        {packageData.isExpired  && (<span
          className={`bg-red-200 inline-block  rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2`}>
          Upłynął termin ważności
        </span>)}
        {packageData.dataLeft < 0.01  && (<span
          className={`bg-red-200 inline-block  rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2`}>
          Pakiet wykorzystany
        </span>)}
        {packageData.dataLeft > 0.01 && packageData.isExpired !== true  && (<span
          className={`bg-green-200 inline-block  rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2`}>
          Pakiet może być używany
        </span>)}
      </div>
    </div>)
  }else{
    return (<div className={`${className} max-w-sm rounded overflow-hidden shadow-lg`}>
      <div className="px-6 py-4" key={packageData.usageStart}>
        <div className="text-gray-700 font-bold text-xl mb-2">{packageData.packageName}</div>
        {packageData.packageVariant && (<p className="text-gray-700 text-base">
          Wariant pakietu: {packageData.packageVariant}
        </p>)}
        {packageData.purchaseDate && (<p className="text-gray-700 text-base">
          <div>Data zakupu: {purchaseDate}</div>
        </p>)}
        <p className="text-gray-700 text-base">
          <div>Dane użyte: brak danych (pracujemy z operatorem by dodać tą funkcję w ciągu kilku miesięcy)</div>
        </p>
      </div>
    </div>)
  }
}

export default PackageCard;